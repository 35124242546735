import React from 'react'
import { Link } from 'gatsby'

import FooterIcon from '../images/footer_icon.svg'
import WavingJatin from '../images/waving_jatin.svg'

export default function Footer() {
  return (
    <footer style={FooterWrapper} >
      <div className='footer-inner-wrap '>
        <section>
          <h1 className='footer-header'>Hey There,</h1>
          <p>I appreciate you checking out the blog. My hope is through a fun and curiosity-driven exploration of technical topics, we are able to better understand the fundamentals so that we can better understand the world around us and maybe even build something original.</p>
          <div className='right-align '>
            <p>- Jatin Mehta</p>
          </div>
          <h1 className='footer-header'>More From Jatin</h1>
          <div className='more-links'>
            <Link to="https://medium.com/@jatinm">
              Old Blogs
            </Link>
            <Link to="https://www.jatinmehta.ca/">
              Personal Website
            </Link>
            <Link to="https://www.opp-hub.com/">
              Cool Project - OppHub
            </Link>
          </div>
          <br />
          <br />
          <div>
            Since there is a 110% chance that there is an error somewhere, shoot me <Link to="mailto:mehta.r.jatin@gmail.com">an email</Link> to let me know.
          </div>
          <br />
          <br />
          <div>
            Website developed by Jatin, designed by <Link to="https://cadenchan.design/">Caden</Link>.
          </div>
          <br />
          <br />
          <div className='footer-icon'>
            <img src={FooterIcon} alt="footer icon" />
            <p>© 2022 Fundamentals First. All rights reserved.</p>
          </div>
        </section>

        <section className='footer-img-wrapper'>
          <img src={WavingJatin} alt="Waving Jatin" />
        </section>
      </div>
    </footer>
  )
}

const FooterWrapper = {
  background: "black",
  width: "100%",
  color: "white"
}
